import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
    apiKey: 'AIzaSyAVU2wSZ5z8mUkNMg6LnSRrGgZi0uP8wTM',
    authDomain: 'storimate-test.firebaseapp.com',
    projectId: 'storimate-test',
}

class Firebase {
    constructor() {
        firebase.initializeApp(config)
        this.store = firebase.firestore
        this.auth = firebase.auth
    }

    get polls() {
        return this.store().collection('polls')
    }
}

export default new Firebase()
